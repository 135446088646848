//登录
<template>
  <div class="login_page">
    <!--  粒子效果 -->
    <vue-particles
      class="particles_login"
      color="#dedede"
      :particleOpacity="0.7"
      :particlesNumber="100"
      shapeType="circle"
      :particleSize="4"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.8"
      :linesDistance="150"
      :moveSpeed="4"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>

    <!-- 内容部分 -->
    <div class="content">
      <div class="left">
        <!-- <img class="logle_img" :src="../assets/img/logo.png" /> -->
        <img class="logle_img" :src="appLog" />
      </div>
      <div class="right">
        <!-- 标题 -->
        <!-- <div class="title"><span>智多蛙后台管理系统</span><span class="com_l"></span></div> -->
        <div class="title">
          <span>{{ appName }}</span
          ><span class="com_l"></span>
        </div>

        <!-- 填充视图 -->
        <div class="fill_view"></div>

        <!-- 账号,密码输入部分 -->
        <div class="details">
          <!-- 账号 -->
          <div class="account com_h com_present">
            <i class="iconfont icon-zhanghao com_icon"></i>
            <!-- clearable -->
            <el-input v-model="adminName" maxlength="11" clearable placeholder="请输入账号"></el-input>
          </div>

          <!-- 密码 -->
          <div class="pwd account com_h com_present">
            <i class="iconfont icon-mima com_icon"></i>
            <!--  clearable  autocomplete="new-password" -->
            <el-input v-model="adminPwd" maxlength="20" clearable placeholder="请输入密码" auto-complete="new-password" :type="passwordType"></el-input>
          </div>

          <!-- 自动登录,忘记密码 -->
          <div class="bottom com_h com_present">
            <!-- 自动登录 class="hidden" -->
            <div class="show" @click.stop="sysLoginState" onselectStart="return false">
              <i class="iconfont icon_select" :class="[hasSelected ? 'icon-xuanzhong' : 'icon-danxuanweixuanzhong']"></i>
              <span class="span_msg">记住账号</span>
            </div>
          </div>
        </div>

        <!-- 填充视图 -->
        <div class="fill_view"></div>

        <!-- 登录按钮 -->
        <el-button :loading="showLoading" class="login_bt login_ok" @click.stop="loginAction">登&nbsp;&nbsp;&nbsp;&nbsp;录</el-button>
      </div>
    </div>

    <!-- 选择角色的弹框  -->
    <ChooseRoleDialog
      ref="crd"
      @loginAction="loginHttp"
      v-if="isShowChooseRoleDialg"
      @close="isShowChooseRoleDialg = false"
      :showDialog="isShowChooseRoleDialg"
    ></ChooseRoleDialog>
  </div>
</template>
<script>
// 选择角色的弹框
import ChooseRoleDialog from "@/components/dialog/ChooseRoleDialog.vue";
//导入配置信息
import AppInfo from "../config/AppConig.js";

export default {
  data() {
    return {
      //登录姓名
      adminName: "",

      //登录密码
      adminPwd: "",

      //记录输入的密码
      recordAdiminPwd: "",

      //自动登录是否选中的标识,初次进入需要通过本地保存的状态同步
      hasSelected: false,

      //密码类型
      passwordType: "password",

      //点击登录的控制加载的状态
      loading: false,

      // 正则验证 手机号
      numReg: /^1[3456789]\d{9}$/,

      //登录状态
      showLoading: false,

      //是否显示角色选择的弹框
      isShowChooseRoleDialg: false,

      //名称
      appName: "",

      //图标
      appLog: "",
    };
  },
  created() {
    //初次进入的时候根据localStory中是否记住了账号和密码同步页面状态
    this.initState();
  },
  mounted() {},
  methods: {
    initState() {
      //同步名称和图标
      this.appName = AppInfo.AppName;
      this.appLog = AppInfo.AppLog;

      //同步状态
      let recordState = localStorage.getItem("recordAccount");
      if (recordState == 1) {
        this.adminName = localStorage.getItem("userName");

        this.hasSelected = true;
      } else if (recordState == 0) {
        this.adminName = "";

        this.hasSelected = false;
      }
    },

    //同步自动登录状态的方法,
    sysLoginState() {
      if (!this.hasSelected) {
        if (this.isEmpty(this.adminName)) {
          this.showWarnMsg("请先输入账号");
          return;
        }
      }
      //对页面的保存状态取反
      this.hasSelected = !this.hasSelected;
      //同步存储状态
      this.sysAccountState();
    },

    //登录验证
    loginAction() {
      //点击选中时先判断是否输入了账号和密码
      if (this.isEmpty(this.adminName)) {
        this.showWarnMsg("账号/密码不能为空");
        return;
      }
      if (this.isEmpty(this.adminPwd)) {
        this.showWarnMsg("账号/密码不能为空");
        return;
      }
      if (!this.numReg.test(this.adminName)) {
        this.showWarnMsg("手机号格式不对");
        return;
      }

      this.showLoading = true;

      //这里先请求权限判定接口
      this.httpPrimise();
    },

    //同步记住账号的方法
    sysAccountState() {
      //同步本地保存的登录状态
      if (this.hasSelected) {
        //已经选中,localStory中保存账号和密码
        localStorage.setItem("recordAccount", 1);
        localStorage.setItem("userName", this.adminName);
      } else {
        localStorage.setItem("recordAccount", 0);
        localStorage.setItem("userName", "");
      }
    },

    //权限判定接口
    async httpPrimise() {
      let param = new Object();
      param.type = 1;
      param.tel = this.adminName;
      param.p = this.adminPwd;
      let res = await this.$http.fetchPost(this.$api.PERMISSION, param);
      //获取状态码
      if (res.data.state == 220) {
        //同时是校长和老师账号,则弹出角色选择框
        this.isShowChooseRoleDialg = true;
        this.showLoading = false;
      } else if (res.data.state == 200) {
        //没有登录账号没有同时是校长和老师账号,这直接登录
        this.loginHttp();
      } else {
        this.showErrorMsg(this.getMesage(res.data.state));
        this.showLoading = false;
      }
    },

    //登录接口
    async loginHttp(chooseRole) {
      //登录之前重新同步一下本地保存的账号状态
      this.sysAccountState();
      //在请求服务器进行登录操作
      let param = new Object();
      param.type = 1;
      param.tel = this.adminName;
      param.p = this.adminPwd;
      //登录接口多加一个登录角色的参数
      if (!this.isEmpty(chooseRole)) {
        param.role = chooseRole.role;
      }
      let res = await this.$http.fetchPost(this.$api.LOGIN, param);
      // console.log("返回的结果是:", res);
      if (res.data.state == 200) {
        //改变加载状态
        this.showLoading = false;

        this.showSuccessMsg("登录成功");

        //选择角色弹框后点击确定按钮的的操作
        if (this.$refs.crd) {
          this.$refs.crd.showLoading = false;
          this.isShowChooseRoleDialg = false;
        }

        let userInfo = res.data.data;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        //根据不同角色跳转到不同的初始界面  1老师 2校区教务 3校长 4管理员 5超级管理员
        //校长-跳转我的校区/Home/MySchool
        //老师-跳转 教务中心-我的主页
        //校区教务 暂时不用
        //管理员 校区管理  /Home/CampusManagement
        //超级管理员 校区管理  /Home/CampusManagement

        let role = userInfo.role;

        if (role == 4 || role == 5) {
          //同步左侧菜单的初次进入的选中位置
          sessionStorage.setItem("default_menu_actived_index", "0");
          //超级管理员或者管理员跳转到校区管理界面
          this.$router.replace({
            path: "/Home/CampusManagement",
          });
        } else if (role == 3) {
          //校长 跳转到我的校区页面
          //同步左侧菜单的初次进入的选中位置
          sessionStorage.setItem("default_menu_actived_index", "7");
          this.$router.replace({
            path: "/Home/MySchool",
          });
        } else if (role == 1) {
          //老师 跳转到教务中心-我的主页界面
          //同步左侧菜单的初次进入的选中位置
          sessionStorage.setItem("default_menu_actived_index", "2-6");
          this.$router.replace({
            path: "/Home/MyHomePage",
          });
        }
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
        this.showLoading = false;

        //重置登录按钮
        if (this.$refs.crd) {
          this.$refs.crd.showLoading = false;
        }
      }
    },
  },
  components: {
    ChooseRoleDialog,
  },
};
</script>
<style lang="scss" scoped>
$dark_gray: #889aa4;
.login_page {
  width: 100%;
  height: 100vh;
  background: #43bb61;
  background-size: 100%;
  position: relative;

  .particles_login {
    position: absolute;
    height: 98%;
    width: 100%;
    top: 0;
    left: 0;
  }
  .com_h {
    height: 50px;
  }

  ::v-deep .el-input {
    border: none;
    border-radius: 4px;
    flex: 1;
  }

  ::v-deep .el-input input {
    caret-color: black;
  }

  ::v-deep .el-input__suffix {
    display: flex;
    align-items: center;
    right: 0;
  }

  ::v-deep .el-input__inner {
    height: 100%;
    border: none;
    line-height: 40px;
    padding: 0 6px;
    color: black;
  }
  .content {
    display: flex;
    position: absolute;
    width: 60%;
    height: 60%;
    background: white;
    border-radius: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .left {
      flex: 5;
      display: flex;
      align-items: center;
      justify-content: center;
      .logle_img {
        width: 70%;
        object-fit: contain;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      .com_l {
        margin-left: 16px;
      }
      flex: 3;
      //标题
      .title {
        margin-top: 20%;
        color: black;
        font-size: 22px;
        font-weight: bold;
      }

      //填充部分
      .fill_view {
        flex: 1;
      }

      //输入的账号密码部分
      .details {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .com_present {
          width: 80%;
        }

        .com_icon {
          margin-right: 8px;
          font-weight: bolder;
          font-size: 20px;
          color: #5d34e6;
        }

        //账号部分
        .account {
          border-radius: 8px;
          border: 1px solid #dddddd;
          // border-bottom: 1px solid #cac3c3;
          display: flex;
          align-items: center;
          height: 35px;
          margin-top: 10px;
        }

        //密码部分
        .pwd {
          position: relative;
          .show-pwd {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            font-size: 16px;
            color: $dark_gray;
            cursor: pointer;
            user-select: none;
          }
        }

        //忘记密码,自动登录部分
        .bottom {
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #acacac;

          .hidden {
            visibility: hidden;
          }

          .icon_select,
          .span_msg {
            cursor: pointer;
            // color: #5d34e6;
            color: #43bb61;
          }

          .icon_select {
            font-size: 12px;
          }
          .span_msg {
            margin-left: 8px;
            color: #acacac;
          }

          .forget_pwd {
            cursor: pointer;
          }
        }
      }
      .login_bt {
        height: 40px;
        width: 60%;
        border-radius: 20px;
        cursor: pointer;
        outline: none;
        border: none;
        font-weight: bold;
        color: white;
        margin-bottom: 20%;
      }

      .login_ok {
        background: #43bb61;
      }
    }
  }
}
input {
  -webkit-user-select: auto; /*webkit浏览器*/
}
textarea {
  -webkit-user-select: auto; /*webkit浏览器*/
}
</style>
