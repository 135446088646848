<template>
  <div class="choose_role_dialog">
    <CommonDialog
      @close="close"
      :showDialog="showDialog"
      title="选择角色身份"
      :width="width"
    >
      <template v-slot:content>
        <div class="real_content">
          <!-- 角色选择区域 -->
          <div class="role_list">
            <div
              :class="['role_item', { role_active: curIndex == index }]"
              v-for="(item, index) in roleArr"
              :key="index"
              @click.stop="doItenClick(index)"
            >
              {{ item.name }}
            </div>
          </div>

          <!-- 登录按钮 -->
          <el-button
            :loading="showLoading"
            :class="['login_bt', curIndex == -1 ? 'login_normal' : 'login_ok']"
            @click.stop="loginAction"
            >确&nbsp;&nbsp;&nbsp;&nbsp;定</el-button
          >
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "@/components/CommonDialog";

export default {
  data() {
    return {
      //弹框的宽度
      width: "360px",

      //角色选择的数组
      roleArr: [
        {
          name: "我是校长",
          role: 3,
        },
        {
          name: "我是老师",
          role: 1,
        },
      ],

      //选中的索引
      curIndex: -1,

      //是否加载按钮
      showLoading: false,

      //记录选中的角色
      chooseRole: "",
    };
  },
  created() {},
  methods: {
    //关闭弹框
    close() {
      //触发父组件的关闭方法
      this.$emit("close");
    },

    //条目的选中事件
    doItenClick(index) {
      if (this.curIndex != index) {
        this.curIndex = index;
        //记录选择的用户角色
        this.chooseRole = this.roleArr[index].role;
      }
    },

    //发送登录请求
    loginAction() {
      if (this.curIndex != -1) {
        this.showLoading = true;
        this.$emit("loginAction", { role: this.chooseRole });
      } else {
        this.showWarnMsg("请选择登录的角色类型");
      }
    },
  },
  computed: {},
  components: {
    CommonDialog,
  },
  props: ["showDialog"],
};
</script>
<style lang="scss" scoped>
.choose_role_dialog {
  .real_content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .role_list {
      margin: 30px 0px;
      width: 100%;
      display: flex;
      justify-content: space-around;

      .role_item {
        width: 140px;
        height: 36px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #909399;
      }

      //选中的样式
      .role_active {
        background: #43bb61;
        border: 1px solid #43bb61;
        color: white;
      }
    }

    .login_bt {
      height: 40px;
      width: 200px;
      margin-bottom: 20px;
      border-radius: 20px;
      cursor: pointer;
      outline: none;
      border: none;
      color: white;
      font-weight: bold;
    }

    .login_normal {
      background: #909399;
    }

    .login_ok {
      background: #43bb61;
    }
  }

  ::v-deep .el-dialog {
    top: 50%;
  }

  ::v-deep .el-icon-close:before {
    cursor: pointer;
  }
}
</style>
